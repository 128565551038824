export enum TypeAuteurUrl {
    Autre = "autre",
    Facebook = "facebook",
    Instagram = "instagram",
    Pinterest = "pinterest",
    SiteWeb = "siteWeb",
    Twitter = "twitter",
}

export enum TypeCategorie {
    Recette = "recette",
    Publication = "publication",
}

export enum TypeDuree {
    Rapide = "rapide",
    Moyenne = "moyenne",
    Longue = "longue",
}

export enum TypeEntite {
    Auteur = "auteur",
    Billet = "billet",
    Boisson = "boisson",
    Categorie = "categorie",
    ChoixReponse = "choixReponse",
    Emission = "emission",
    Episode = "episode",
    Etape = "etape",
    Filtre = "filtre",
    FiltreItem = "filtreItem",
    Image = "image",
    ImageDeclinaison = "imageDeclinaison",
    ImageMaster = "imageMaster",
    Ingredient = "ingredient",
    Lexique = "lexique",
    Particularite = "particularite",
    Publication = "publication",
    Question = "question",
    Quiz = "quiz",
    Recette = "recette",
    Rubrique = "rubrique",
    SectionIngredients = "sectionIngredients",
    SectionInstructions = "sectionInstructions",
    Source = "source",
    Temps = "temps",
    Thematique = "thematique",
    ValeurNutritive = "valeurNutritive",
}

export enum TypeEntiteFrontend {
    Publicite = "publicite",
}

export type TypeEntiteExtended = TypeEntite | TypeEntiteFrontend;

export enum TypePublication {
    Article = "article",
    Quiz = "quiz",
}

export enum Viewport {
    Desktop = "desktop",
    Mobile = "mobile",
}
