import React from "react";
import classNames from "classnames";

import styles from "./etoiles.module.scss";
import { NoEtoilesActives } from "@/types/recette";

type PropTypes = {
    className?: string;
    noEtoilesActives: NoEtoilesActives;
    showTotal?: boolean;
    totaEvaluations?: number;
};

const Etoiles = ({
    className = "",
    noEtoilesActives = 0,
    showTotal = false,
    totaEvaluations = 0,
}: PropTypes) => {
    const renderEtoiles = () => {
        const items = [];
        let i = 1;
        for (i; i <= 5; i++) {
            items.push(
                <span
                    className={classNames({
                        "icon-etoile": true,
                        [styles.active]: i <= noEtoilesActives,
                    })}
                    key={`etoile_${i}`}
                />,
            );
        }
        return items;
    };

    return (
        <div
            data-component="Etoiles"
            className={classNames({
                [styles.etoilesContainer]: true,
                [className]: className,
            })}
        >
            {renderEtoiles()}
            {showTotal && <>{`(${totaEvaluations})`}</>}
        </div>
    );
};

export default Etoiles;
