import React from "react";
import UiEpisode from "./uiEpisode";
import { Episode } from "@/types/emissions";

type PropTypes = {
    episode: Episode;
};

const EpisodeRecent = ({ episode }: PropTypes) => {
    const {
        dateDiffusion,
        description,
        emission,
        id,
        imageUrlTemplate,
        isMediaDisponible,
        noEpisode,
        noSaison,
        recettes,
        titre,
        type,
    } = episode;

    const {
        id: emissionId,
        images: emissionImages,
        nom: emissionNom,
        slug: emissionSlug,
    } = emission;

    return (
        <UiEpisode
            dateDiffusion={dateDiffusion}
            description={description}
            emissionId={emissionId}
            emission={emission}
            emissionImages={emissionImages}
            emissionNom={emissionNom}
            emissionSlug={emissionSlug}
            id={id}
            imageUrlTemplate={imageUrlTemplate}
            isMediaDisponible={isMediaDisponible}
            noEpisode={noEpisode}
            noSaison={noSaison}
            recettes={recettes}
            titre={titre}
            type={type}
        />
    );
};

export default EpisodeRecent;
