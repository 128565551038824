import React from "react";
import { Emission as EmissionType } from "@/types/emissions";
import { path } from "@/utils/path";
import { TYPE_CONTENU, TYPE_GABARIT } from "@/utils/enums";
import Link from "next/link";
import Image from "@/ui/image";

import classNames from "classnames";
import styles from "./emission.module.scss";

type PropTypes = {
    emission: EmissionType;
};

const Emission = ({ emission }: PropTypes) => {
    const { id, nom, slug, images } = emission;
    const url = path.fill(path.EMISSION, { type: TYPE_CONTENU.EMISSION, id, slug });

    return (
        <div data-component="Emission" className={classNames(styles.me, {})} key={id}>
            <Link href={url}>
                <div className={styles.imgZone}>
                    <Image
                        images={images}
                        gabarits={[TYPE_GABARIT.FORMAT_1X1]}
                        alt={`image représentant l'émission ${nom}`}
                    />
                </div>
                <Image
                    className={styles.logo}
                    images={images}
                    gabarits={[TYPE_GABARIT.FORMAT_LOGO]}
                    alt={`logo de l'émission ${nom}`}
                />
            </Link>
        </div>
    );
};

export default Emission;
