import classNames from "classnames";
import { CldImage } from "next-cloudinary";
import Image from "next/image";
import React, { useState } from "react";
import styles from "./image.module.scss";

export const IMAGE_TEMPLATE_SIZES = {
    96: { width: 96, height: 54 },
    176: { width: 176, height: 99 },
    224: { width: 224, height: 126 },
    240: { width: 240, height: 135 },
    256: { width: 256, height: 144 },
    288: { width: 288, height: 162 },
    304: { width: 304, height: 171 },
    320: { width: 320, height: 180 },
    352: { width: 352, height: 198 },
    384: { width: 384, height: 216 },
    400: { width: 400, height: 225 },
    464: { width: 464, height: 261 },
    480: { width: 480, height: 270 },
    512: { width: 512, height: 288 },
    688: { width: 688, height: 387 },
    768: { width: 768, height: 432 },
    800: { width: 800, height: 450 },
    1152: { width: 1152, height: 648 },
    1920: { width: 1920, height: 1080 },
};
// https://images.telequebec.tv/medias/038259/default/w{w}_h{h}.jpg

type SizesKeys = keyof typeof IMAGE_TEMPLATE_SIZES;
type SizesValues = (typeof IMAGE_TEMPLATE_SIZES)[SizesKeys];

type PropTypes = {
    className?: string;
    imageUrlTemplate: string;
    size: SizesValues;
    alt: string;
};

export const getImageUrlFromTemplate = (imageUrlTemplate: string, size: SizesValues) => {
    const imgTmplt = imageUrlTemplate || "https://cdn.telequebec.tv/Medias/En_direct/w{w}_h{h}.jpg";

    // More details on CUIS-507
    const imgTmpltCdn = imgTmplt.replace(
        "https://images.telequebec.tv",
        "https://cdn.telequebec.tv",
    );

    const { width, height } = size;
    return imgTmpltCdn.replace("{w}", width.toString()).replace("{h}", height.toString());
};

const ImageUrlTemplate = (props: PropTypes) => {
    const { className, imageUrlTemplate, size, alt } = props;
    const [srcFallback, setSrcFallback] = useState<string | null>(null);

    const src = getImageUrlFromTemplate(imageUrlTemplate, size);

    return (
        <>
            {process.env.NODE_ENV !== "development" ? (
                <Image
                    alt={alt}
                    className={classNames(className, styles.image)}
                    src={srcFallback || src}
                    height={size.height}
                    width={size.width}
                    onError={() => setSrcFallback("/images/placeholders/logo/16x9/w1200_h675.jpg")}
                />
            ) : (
                <CldImage
                    alt={alt}
                    className={classNames(className, styles.image)}
                    deliveryType="fetch"
                    src={srcFallback || src}
                    defaultImage="/images/placeholders/logo/16x9/w1200_h675.jpg"
                    height={size.height}
                    width={size.width}
                />
            )}
        </>
    );
};

export default ImageUrlTemplate;
