import React from 'react';
import UiRecette from './uiRecette';
import type { Recette } from '@/types/recette';


type RecetteProps = {
    recette: Recette,
    className?: string
};

const Recette = ({recette, className}: RecetteProps) => {
    const {
        dureeTotal,
        evaluations,
        id,
        images,
        slug,
        titre,
    } = recette;

    return (
        <UiRecette
            className={className}
            dureeTotal={dureeTotal}
            evaluations={evaluations}
            id={id}
            images={images}
            slug={slug}
            titre={titre}
        />
    );
};

export default Recette;
