import React from "react";
import { Recette } from "@/types/recette";
import UiRecette from "./uiRecette";

type PropTypes = {
    recette: Recette;
};

const RecetteRecent = ({ recette }: PropTypes) => {
    const { id, images, media, slug, source, titre, type } = recette;

    return (
        <UiRecette
            id={id}
            images={images}
            isMediaDisponible={media?.canPlay}
            slug={slug}
            source={source}
            titre={titre}
            type={type}
            sendLinkToVideo
        />
    );
};

export default RecetteRecent;
