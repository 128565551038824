import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { path } from "@/utils/path";
import { Episode } from "@/types/emissions";
import { Image as ImageType } from "@/types/image";

import ImageUrlTemplate, { IMAGE_TEMPLATE_SIZES } from "@/ui/image/imageUrlTemplate";

import Image from "@/ui/image";
import styles from "../uiContenuRecent.module.scss";
import { TYPE_GABARIT, TYPE_CONTENU, LABEL_TAGINTO } from "@/utils/enums";
import PlayIconInTo from "@/ui/playIconInTo";
import TagInTo from "@/ui/tagInTo";

type PropTypes = Episode & {
    className?: string;
    emissionImages?: ImageType[];
    emissionNom: string;
    emissionSlug: string;
};

// https://cuisinez.telequebec.tv/images/interface/fallback/logo/w368_h207.jpg
const UiEpisode = ({
    className = "",
    emissionId,
    emissionImages,
    emissionNom,
    emissionSlug,
    id,
    imageUrlTemplate,
    isMediaDisponible = false,
    noEpisode,
    noSaison,
    titre,
}: PropTypes) => {
    const url = path.fill(path.EPISODEBYSAISON, {
        type: TYPE_CONTENU.EPISODE,
        id: emissionId,
        slug: emissionSlug,
        noSaison,
        noEpisode,
    });

    if (!url) {
        return null;
    }

    return (
        <div
            data-component="UIEpisode"
            className={classNames(styles.me, {
                [className]: className,
            })}
            key={id}
        >
            <Link href={url} aria-label={titre}>
                <TagInTo label={LABEL_TAGINTO.EPISODE} />
                <div className={styles.imgLink}>
                    {isMediaDisponible && <PlayIconInTo />}
                    <ImageUrlTemplate
                        imageUrlTemplate={imageUrlTemplate || ""}
                        size={IMAGE_TEMPLATE_SIZES[800]}
                        alt={`${emissionNom}: ${titre}`}
                    />
                </div>
                <div className={styles.titleZone}>
                    <Image
                        ariaLabel={emissionNom}
                        alt={emissionNom}
                        className={styles.logo}
                        images={emissionImages}
                        gabarits={[TYPE_GABARIT.FORMAT_LOGO]}
                    />
                    <h3 className={styles.titleName}>{titre}</h3>
                </div>
            </Link>
        </div>
    );
};

export default UiEpisode;
