import React from "react";
import classNames from "classnames";
import Link from "next/link";

import { TYPE_GABARIT } from "../../../utils/enums";
import { path } from "../../../utils/path";

import Etoiles from "../../etoiles/etoiles";
import Image from "../../image";
import SanitizeHtml from "../../sanitizeHTML";

import styles from "./uiRecette.module.scss";
import { Image as ImageType } from "@/types/image";
import { NoEtoilesActives } from "@/types/recette";

type PropTypes = {
    className?: string;
    dureeTotal?: number;
    evaluations?: {
        moyenne: NoEtoilesActives;
        total: number;
    };
    id: number | string;
    images?: ImageType[];
    slug?: string;
    titre: string;
};

// https://cuisinez.telequebec.tv/images/interface/fallback/logo/w368_h207.jpg
const UiRecette = ({
    className = "",
    dureeTotal,
    evaluations,
    id,
    images,
    slug,
    titre,
}: PropTypes) => {
    const url = path.fill(path.RECETTE, { type: "recette", id, slug });

    if (!url) {
        return null;
    }

    return (
        <div
            data-component="UiRecette"
            className={classNames(
                styles.me,
                {
                    [className]: className,
                },
                "uiRecette",
            )}
            key={id}
        >
            <Link href={url} className={styles.linkImage}>
                <figure
                    aria-label={titre}
                    className={classNames({
                        [styles.figure]: true,
                    })}
                >
                    <Image
                        className={styles.img}
                        images={images}
                        gabarits={[TYPE_GABARIT.FORMAT_1X1]}
                        alt={titre}
                    />
                </figure>
            </Link>
            <div className={styles.section}>
                <Link href={url}>
                    <SanitizeHtml className={styles.titreCart} html={titre} tag="h2" />
                </Link>
                <div className={styles.info}>
                    <span className={styles.evaluation}>
                        {evaluations && evaluations.moyenne !== null && (
                            <Etoiles noEtoilesActives={evaluations.moyenne} />
                        )}
                    </span>
                    {dureeTotal && dureeTotal > 0 ? (
                        <span className={styles.time}>{`${dureeTotal} min`}</span>
                    ): null}
                </div>
            </div>
        </div>
    );
};

export default UiRecette;
