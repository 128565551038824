import React from "react";
import classNames from "classnames";

import styles from "./tagInTo.module.scss";

type PropTypes = {
    className?: string;
    label: string;
};

const TagInTo = ({ className, label }: PropTypes) => {
    return (
        <span data-component="TagInfo" className={classNames(styles.me, className)}>
            {label}
        </span>
    );
};

export default TagInTo;
