import React from "react";
import { Categorie as CategorieType } from "@/types/categorie";
import styles from './categorie.module.scss';
import Link from "next/link";
import Image from "../../image";
import path from "@/utils/path";
import { TYPE_GABARIT } from "@/utils/enums";

type PropTypes = {
    categorie: CategorieType;
};

const Categorie = ({ categorie }: PropTypes) => {
    const { id, images, nom, slug } = categorie;
    const urlCategorie = path.fill(path.CATEGORY, {id, slug});

    return (
        <div data-component="UiCategorie" className={styles.me}>
            <Link href={urlCategorie}>
                <Image
                    ariaLabel={nom}
                    className={styles.tempImage}
                    images={images}
                    gabarits={[TYPE_GABARIT.FORMAT_2X3, TYPE_GABARIT.FORMAT_16X9]}
                />
            </Link>
            <h3 className={styles.title}>
                <Link href={urlCategorie}>{nom}</Link>
            </h3>
        </div>
    );
};

export default Categorie;
