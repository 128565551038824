import React from "react";
import UiEpisode from "./uiEpisode";
import { VariantValues } from "../cartes.constants";
import { Episode as EpisodeType } from "@/types/emissions";

type PropTypes = {
    episode: EpisodeType;
    variant: VariantValues;
};

const Episode = ({ episode, variant }: PropTypes) => {
    const {
        emission,
        id,
        imageUrlTemplate,
        noEpisode,
        noSaison,
        recettes,
        titre,
    } = episode;

    const { id: emissionId, nom: emissionNom, slug: emissionSlug } = emission;

    return (
        <UiEpisode
            emissionId={emissionId}
            emissionNom={emissionNom}
            emissionSlug={emissionSlug}
            id={id}
            imageUrlTemplate={imageUrlTemplate}
            noEpisode={noEpisode}
            noSaison={noSaison}
            recettes={recettes}
            titre={titre}
            variant={variant}
        />
    );
};

export default Episode;
