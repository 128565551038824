import React from "react";
import classNames from "classnames";
import Link from "next/link";
import { path } from "@/utils/path";
import { Recette, RecetteCompact } from "@/types/recette";

import ImageUrlTemplate, {
    getImageUrlFromTemplate,
    IMAGE_TEMPLATE_SIZES,
} from "../../image/imageUrlTemplate";

import styles from "./uiEpisode.module.scss";
import { TYPE_CONTENU } from "@/utils/enums";
import { VARIANT, VariantValues } from "../cartes.constants";

type PropTypes = {
    className?: string;
    emissionId: number;
    emissionNom: string;
    emissionSlug: string;
    id: number;
    imageUrlTemplate?: string;
    noEpisode?: number;
    noSaison?: number;
    recettes?: Recette[] | RecetteCompact[];
    titre: string;
    variant?: VariantValues;
};

// https://cuisinez.telequebec.tv/images/interface/fallback/logo/w368_h207.jpg
const UiEpisode = ({
    className = "",
    emissionId,
    emissionNom,
    emissionSlug,
    id,
    imageUrlTemplate = "",
    noEpisode,
    noSaison,
    recettes = [],
    titre,
    variant = VARIANT.COLLECTION,
}: PropTypes) => {
    const url = path.fill(path.EPISODEBYSAISON, {
        type: TYPE_CONTENU.EPISODE,
        id: emissionId,
        slug: emissionSlug,
        noSaison,
        noEpisode,
    });

    const figureStyle = {
        backgroundImage: `url("${getImageUrlFromTemplate(imageUrlTemplate, IMAGE_TEMPLATE_SIZES[800])}")`,
    };

    if (!url) {
        return null;
    }

    return (
        <div
            data-component="UiEpisode"
            className={classNames(styles.me, {
                [className]: className,
                [styles[variant]]: typeof styles[variant] !== "undefined",
            })}
            key={id}
        >
            <div className={styles.tag}>Épisode</div>
            <div className={styles.imgZone}>
                <div className={styles.bg} style={figureStyle} />
                <Link href={url} className={styles.imgLink}>
                    <ImageUrlTemplate
                        imageUrlTemplate={imageUrlTemplate}
                        size={IMAGE_TEMPLATE_SIZES[800]}
                        alt={`${emissionNom}${titre}`}
                        className={styles.img}
                    />
                </Link>
                {recettes && recettes.length > 0 && (
                    <div className={styles.nbrRecettes}>
                        {`${recettes.length} recette${recettes.length > 1 ? "s" : ""}`}
                    </div>
                )}
            </div>
            <div className={styles.titleZone}>
                <h3 className={styles.titleName}>
                    <Link href={url}>{emissionNom}</Link>
                </h3>
                <h4 className={styles.titleCard}>
                    <Link href={url}>{titre}</Link>
                </h4>
            </div>
        </div>
    );
};

export default UiEpisode;
