import React from "react";

import { Publication as PublicationType, PublicationCompact } from "@/types/publication";
import UiPublication from "./uiPublication";

import { VARIANT as UIVARIANT, VariantValues } from "../cartes.constants";

export const VARIANT = UIVARIANT;

type PropTypes = {
    className?: string;
    publication: PublicationType | PublicationCompact;
    variant?: VariantValues;
};

const Publication = ({ className, publication, variant }: PropTypes) => {
    const { auteur, categories, id, images, titre, publicationType, slug } = publication;

    return (
        <UiPublication
            auteur={auteur}
            categories={categories}
            className={className}
            id={id}
            images={images}
            slug={slug}
            titre={titre}
            type={publicationType}
            variant={variant}
        />
    );
};

export default Publication;
